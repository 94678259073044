import React from 'react';

function NavigationMenu({ onLogout }: { onLogout: () => void }) {
  return (
    <div className="w-64 bg-black text-white p-4 flex flex-col">
      <h1 className="text-2xl font-bold mb-6">BAA Call Assistant</h1>
      <nav className="flex-1">
        <ul>
          <li className="mb-2">
            <button className="block p-2 rounded hover:bg-white hover:text-black" onClick={() => {/* Add your function here */}}>
              Call Assistant
            </button>
          </li>
          <li className="mb-2">
            <button className="block p-2 rounded hover:bg-white hover:text-black" onClick={() => {/* Add your function here */}}>
              Knowledge Store
            </button>
          </li>
        </ul>
      </nav>
      <button
        className="mt-auto bg-blue-500 text-white rounded p-2"
        onClick={() => window.location.reload()}
      >
        New Chat
      </button>
    </div>
  );
}

export default NavigationMenu;