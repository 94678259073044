import React from 'react';
import NavigationMenu from './NavigationMenu';
import Chat from './Chat';

function AppShell({ onLogout }: { onLogout: () => void }) {
  return (
    <div className="flex h-screen">
      <NavigationMenu onLogout={onLogout} />
      <Chat />
    </div>
  );
}

export default AppShell;