import React from 'react';

const LandingPage: React.FC = () => {
    return (
        <div className="font-sans bg-gray-100">
            {/* Navigation */}
            <nav className="bg-white shadow-lg">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between">
                        <div className="flex space-x-7">
                            <div>
                                <button className="flex items-center py-4 px-2 bg-transparent text-blue-600 hover:underline">
                                    <span className="font-semibold text-gray-500 text-lg">Volte</span>
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center space-x-3">
                            <a href="#hero" className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-blue-500 hover:text-white transition duration-300">Home</a>
                            <a href="#features" className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-blue-500 hover:text-white transition duration-300">Features</a>
                            <a href="#benefits" className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-blue-500 hover:text-white transition duration-300">Benefits</a>
                            <a href="#how-it-works" className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-blue-500 hover:text-white transition duration-300">How It Works</a>
                            <a href="/login" className="bg-blue-600 text-white font-medium py-2 px-4 rounded hover:bg-blue-700 transition duration-300">Login</a>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <header id="hero" className="bg-gradient-to-r from-blue-600 to-blue-800 text-white">
                <div className="container mx-auto px-6 py-24">
                    <div className="max-w-3xl mx-auto text-center">
                        <h1 className="text-5xl font-bold mb-6">Supercharge Your Organization's Knowledge with Volte</h1>
                        <h2 className="text-2xl mb-8">Unlock the power of AI-driven knowledge management for seamless information access and enhanced productivity</h2>
                        <button className="bg-white text-blue-600 font-bold py-3 px-8 rounded-full hover:bg-blue-100 transition duration-300 text-lg">
                            Get Started
                        </button>
                    </div>
                </div>
            </header>

            {/* Value Proposition */}
            <section id="features" className="py-20 bg-white">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold text-center mb-12">What is Volte Knowledge Agent?</h2>
                    <p className="text-xl text-center mb-16 max-w-3xl mx-auto">Volte Knowledge Agent is an AI-powered platform that revolutionizes how organizations manage, access, and utilize their collective knowledge.</p>
                    <div className="flex flex-wrap justify-center -mx-4">
                        <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
                            <div className="text-center p-6 bg-gray-50 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                                <i className="fas fa-brain text-5xl text-blue-500 mb-6"></i>
                                <h3 className="text-2xl font-semibold mb-4">AI-Powered Insights</h3>
                                <p className="text-gray-600">Harness the power of artificial intelligence to extract valuable insights from your data.</p>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
                            <div className="text-center p-6 bg-gray-50 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                                <i className="fas fa-search text-5xl text-blue-500 mb-6"></i>
                                <h3 className="text-2xl font-semibold mb-4">Intelligent Search</h3>
                                <p className="text-gray-600">Find the information you need quickly with our advanced search capabilities.</p>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
                            <div className="text-center p-6 bg-gray-50 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                                <i className="fas fa-users text-5xl text-blue-500 mb-6"></i>
                                <h3 className="text-2xl font-semibold mb-4">Collaboration Tools</h3>
                                <p className="text-gray-600">Foster teamwork and knowledge sharing across your entire organization.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Problem Statement */}
            <section id="benefits" className="py-20 bg-gray-100">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold text-center mb-16">The Knowledge Management Challenge</h2>
                    <div className="flex flex-wrap justify-center -mx-4">
                        <div className="w-full md:w-1/3 px-4 mb-8">
                            <div className="bg-white rounded-lg shadow-md p-8 h-full">
                                <h3 className="text-2xl font-semibold mb-4">Information Overload</h3>
                                <p className="text-gray-600">80% of employees waste time searching for information they need to do their jobs effectively.</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/3 px-4 mb-8">
                            <div className="bg-white rounded-lg shadow-md p-8 h-full">
                                <h3 className="text-2xl font-semibold mb-4">Knowledge Silos</h3>
                                <p className="text-gray-600">60% of companies report that knowledge is not shared effectively across departments.</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/3 px-4 mb-8">
                            <div className="bg-white rounded-lg shadow-md p-8 h-full">
                                <h3 className="text-2xl font-semibold mb-4">Employee Turnover</h3>
                                <p className="text-gray-600">When an employee leaves, 70% of their unique knowledge walks out the door with them.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Solution Showcase */}
            <section id="how-it-works" className="py-20 bg-white">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold text-center mb-16">How Volte Solves These Challenges</h2>
                    <div className="flex flex-wrap items-center">
                        <div className="w-full lg:w-1/2 mb-12 lg:mb-0">
                            <img src="/api/placeholder/600/400" alt="Volte Knowledge Agent Dashboard" className="rounded-lg shadow-xl mx-auto" />
                        </div>
                        <div className="w-full lg:w-1/2 lg:pl-12">
                            <ul className="space-y-6">
                                <li className="flex items-center">
                                    <i className="fas fa-check-circle text-green-500 text-2xl mr-4"></i>
                                    <span className="text-lg">Centralizes all organizational knowledge in one accessible platform</span>
                                </li>
                                <li className="flex items-center">
                                    <i className="fas fa-check-circle text-green-500 text-2xl mr-4"></i>
                                    <span className="text-lg">Uses AI to intelligently categorize and connect information</span>
                                </li>
                                <li className="flex items-center">
                                    <i className="fas fa-check-circle text-green-500 text-2xl mr-4"></i>
                                    <span className="text-lg">Provides powerful search and discovery tools for quick access to relevant data</span>
                                </li>
                                <li className="flex items-center">
                                    <i className="fas fa-check-circle text-green-500 text-2xl mr-4"></i>
                                    <span className="text-lg">Facilitates knowledge sharing and collaboration across teams</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* Key Benefits */}
            <section className="py-20 bg-gray-100">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold text-center mb-16">Key Benefits of Volte</h2>
                    <div className="flex flex-wrap justify-center -mx-4">
                        <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                            <div className="bg-white rounded-lg shadow-md p-8 h-full text-center">
                                <i className="fas fa-tachometer-alt text-5xl text-blue-500 mb-6"></i>
                                <h3 className="text-2xl font-semibold mb-4">Increased Productivity</h3>
                                <p className="text-gray-600">Employees spend less time searching for information and more time on high-value tasks.</p>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                            <div className="bg-white rounded-lg shadow-md p-8 h-full text-center">
                                <i className="fas fa-lightbulb text-5xl text-blue-500 mb-6"></i>
                                <h3 className="text-2xl font-semibold mb-4">Enhanced Innovation</h3>
                                <p className="text-gray-600">Break down silos and foster cross-pollination of ideas across your organization.</p>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                            <div className="bg-white rounded-lg shadow-md p-8 h-full text-center">
                                <i className="fas fa-chart-line text-5xl text-blue-500 mb-6"></i>
                                <h3 className="text-2xl font-semibold mb-4">Improved Decision Making</h3>
                                <p className="text-gray-600">Access to comprehensive, up-to-date information leads to better-informed decisions.</p>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                            <div className="bg-white rounded-lg shadow-md p-8 h-full text-center">
                                <i className="fas fa-graduation-cap text-5xl text-blue-500 mb-6"></i>
                                <h3 className="text-2xl font-semibold mb-4">Accelerated Onboarding</h3>
                                <p className="text-gray-600">New employees can quickly access the knowledge they need to become productive.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* How It Works */}
            <section className="py-20 bg-white">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold text-center mb-16">How Volte Works</h2>
                    <div className="flex flex-wrap justify-center -mx-4">
                        <div className="w-full md:w-1/3 px-4 mb-8">
                            <div className="text-center">
                                <div className="w-16 h-16 rounded-full bg-blue-500 flex items-center justify-center text-white text-2xl font-bold mb-6 mx-auto">1</div>
                                <h3 className="text-2xl font-semibold mb-4">Integration</h3>
                                <p className="text-gray-600">We integrate Volte with your existing systems and data sources.</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/3 px-4 mb-8">
                            <div className="text-center">
                                <div className="w-16 h-16 rounded-full bg-blue-500 flex items-center justify-center text-white text-2xl font-bold mb-6 mx-auto">2</div>
                                <h3 className="text-2xl font-semibold mb-4">AI Processing</h3>
                                <p className="text-gray-600">Our AI algorithms process and organize your organizational knowledge.</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/3 px-4 mb-8">
                            <div className="text-center">
                                <div className="w-16 h-16 rounded-full bg-blue-500 flex items-center justify-center text-white text-2xl font-bold mb-6 mx-auto">3</div>
                                <h3 className="text-2xl font-semibold mb-4">User Access</h3>
                                <p className="text-gray-600">Employees access the platform through an intuitive interface.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Use Cases / Success Stories */}
            <section className="py-20 bg-gray-100">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold text-center mb-16">Success Stories</h2>
                    <div className="flex flex-wrap justify-center -mx-4">
                        <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                            <div className="bg-white rounded-lg shadow-md p-8 h-full">
                                <img src="/api/placeholder/100/100" alt="Company Logo" className="w-20 h-20 rounded-full mb-6 mx-auto" />
                                <h3 className="text-2xl font-semibold mb-4 text-center">TechCorp</h3>
                                <p className="mb-6 text-gray-600 italic">"Volte helped us reduce information search time by 50% and improved cross-team collaboration significantly."</p>
                                <p className="text-sm text-gray-500 text-center">- John Doe, CTO</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                            <div className="bg-white rounded-lg shadow-md p-8 h-full">
                                <img src="/api/placeholder/100/100" alt="Company Logo" className="w-20 h-20 rounded-full mb-6 mx-auto" />
                                <h3 className="text-2xl font-semibold mb-4 text-center">InnovateCo</h3>
                                <p className="mb-6 text-gray-600 italic">"With Volte, we've seen a 30% increase in innovation output due to better knowledge sharing."</p>
                                <p className="text-sm text-gray-500 text-center">- Jane Smith, Innovation Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Features Deep Dive */}
            <section className="py-20 bg-white">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold text-center mb-16">Features Deep Dive</h2>
                    <div className="space-y-12">
                        <div className="flex flex-wrap items-center">
                            <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
                                <img src="/api/placeholder/600/400" alt="AI-Powered Search" className="rounded-lg shadow-xl mx-auto" />
                            </div>
                            <div className="w-full lg:w-1/2 lg:pl-12">
                                <h3 className="text-3xl font-semibold mb-4">AI-Powered Search</h3>
                                <p className="text-gray-600 mb-6">Our advanced AI algorithms understand context and intent, delivering more accurate and relevant search results.</p>
                                <ul className="space-y-2 text-gray-600">
                                    <li className="flex items-center"><i className="fas fa-check text-green-500 mr-2"></i> Natural language processing</li>
                                    <li className="flex items-center"><i className="fas fa-check text-green-500 mr-2"></i> Contextual understanding</li>
                                    <li className="flex items-center"><i className="fas fa-check text-green-500 mr-2"></i> Personalized results based on user role and history</li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center flex-row-reverse">
                            <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
                                <img src="/api/placeholder/600/400" alt="Knowledge Graph" className="rounded-lg shadow-xl mx-auto" />
                            </div>
                            <div className="w-full lg:w-1/2 lg:pr-12">
                                <h3 className="text-3xl font-semibold mb-4">Knowledge Graph</h3>
                                <p className="text-gray-600 mb-6">Visualize connections between different pieces of information to uncover insights and relationships.</p>
                                <ul className="space-y-2 text-gray-600">
                                    <li className="flex items-center"><i className="fas fa-check text-green-500 mr-2"></i> Interactive visualization</li>
                                    <li className="flex items-center"><i className="fas fa-check text-green-500 mr-2"></i> Discover hidden connections</li>
                                    <li className="flex items-center"><i className="fas fa-check text-green-500 mr-2"></i> Easily navigate complex information landscapes</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* FAQ Section */}
            <section className="py-20 bg-gray-100">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold text-center mb-16">Frequently Asked Questions</h2>
                    <div className="max-w-3xl mx-auto space-y-8">
                        <div>
                            <h3 className="text-2xl font-semibold mb-2">How secure is Volte?</h3>
                            <p className="text-gray-600">Volte employs state-of-the-art encryption and security measures to ensure your data remains protected. We are compliant with industry standards and regularly undergo security audits.</p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold mb-2">Can Volte integrate with our existing tools?</h3>
                            <p className="text-gray-600">Yes, Volte is designed to seamlessly integrate with a wide range of tools and platforms. Our team will work with you to ensure smooth integration with your existing systems.</p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold mb-2">How long does it take to implement Volte?</h3>
                            <p className="text-gray-600">The implementation time can vary depending on the size and complexity of your organization. Typically, basic setup can be completed within a few weeks, with full integration and customization taking 1-3 months.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Call-to-Action */}
            <section className="py-20 bg-blue-600 text-white">
                <div className="container mx-auto px-6 text-center">
                    <h2 className="text-4xl font-bold mb-8">Ready to Transform Your Organization's Knowledge Management?</h2>
                    <p className="text-xl mb-12 max-w-3xl mx-auto">Join the ranks of leading companies leveraging Volte to boost productivity, foster innovation, and make better decisions.</p>
                    <div className="max-w-md mx-auto">
                        <form className="flex flex-col sm:flex-row gap-4">
                            <input
                                type="email"
                                placeholder="Enter your email"
                                className="flex-grow py-3 px-4 rounded-full text-blue-600 focus:outline-none focus:ring-2 focus:ring-white"
                                required
                            />
                            <button
                                type="submit"
                                className="bg-white text-blue-600 font-bold py-3 px-8 rounded-full hover:bg-blue-100 transition duration-300 text-lg"
                            >
                                Get in touch
                            </button>
                        </form>
                        <p className="mt-4 text-sm">We'll get back to you shortly to discuss how Volte can help your organization.</p>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="py-6 bg-gray-800 text-white">
                <div className="container mx-auto text-center">
                    <div className="space-x-4">
                        <a href="/terms" className="hover:underline">Terms of Service</a>
                        <a href="/privacy" className="hover:underline">Privacy Policy</a>
                    </div>
                    <p className="mt-4">&copy; {new Date().getFullYear()} Volte Technologies, LLC. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;