import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AppShell from './components/AppShell';
import Login from './components/Login';
import LandingPage from './components/LandingPage';
import './global.css';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import AuthCallback from './components/AuthCallback';

function App() {
  const [authenticated, setAuthenticated] = useState<boolean | null>(null); // Initialize as null

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        setAuthenticated(false);
      }
    } catch (err) {
      console.error('Logout error:', err);
    }
  };

  useEffect(() => {
    // Check authentication status on component mount
    const checkAuth = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/status`, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setAuthenticated(data.status === 'authenticated');
        } else {
          setAuthenticated(false);
        }
      } catch (err) {
        console.error('Auth check error:', err);
        setAuthenticated(false);
      }
    };
    checkAuth();
  }, []);

  if (authenticated === null) {
    // Optionally, render a loading indicator
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route 
          path="/login" 
          element={
            authenticated ? (
              <Navigate to="/app" replace />
            ) : (
              <Login setAuthenticated={setAuthenticated as React.Dispatch<React.SetStateAction<boolean>>} />
            )
          } 
        />
        <Route 
          path="/register" 
          element={
            authenticated ? (
              <Navigate to="/app" replace />
            ) : (
              <Register />
            )
          } 
        />
        <Route 
          path="/forgot-password" 
          element={
            authenticated ? (
              <Navigate to="/app" replace />
            ) : (
              <ForgotPassword />
            )
          } 
        />
        <Route 
          path="/reset-password/:token" 
          element={
            authenticated ? (
              <Navigate to="/app" replace />
            ) : (
              <ResetPassword />
            )
          } 
        />
        <Route 
          path="/app" 
          element={
            authenticated ? (
              <AppShell onLogout={handleLogout} />
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route 
          path="/auth-callback" 
          element={<AuthCallback setAuthenticated={setAuthenticated} />} 
        />
      </Routes>
    </Router>
  );
}

export default App;
