import React, { useState, useEffect } from 'react'; // Added useEffect import
import Message from './Message';
import botAvatar from '../assets/bot-avatar.png';


function Chat() {
  const [messages, setMessages] = useState<{ sender: string; content: string; timestamp: Date; avatar?: string; name?: string }[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [userName, setUserName] = useState('');
  const [userInitial, setUserInitial] = useState('U');

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        console.log('Fetching user data...');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/status`, {
          credentials: 'include',
        });
        console.log('Response status:', response.status);
        if (response.ok) {
          const data = await response.json();
          console.log('User data:', data);
          setUserName(data.name || 'Unknown User'); // Set user name from response
          setUserInitial((data.name && data.name.charAt(0)) || 'U');
        } else {
          console.error('Failed to fetch user data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching user name:', error);
      }
    };

    fetchUserName();
  }, []); // Empty dependency array to run once on mount

  const handleSend = async () => {
    if (inputValue.trim() === '') return;

    const userMessage = {
      sender: 'user',
      content: inputValue,
      timestamp: new Date(),
      name: userName,
      avatar: userInitial,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);

    // Send the message to the backend and get a response
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chat`, { // Updated URL
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: inputValue }),
        credentials: 'include',
      });

      if (response.status === 401) {
        // Handle unauthorized access, perhaps by redirecting to login
        // For example, set an authentication state or show a message
        return;
      }

      const data = await response.json();
      const botMessage = {
        sender: 'bot',
        content: data.response,
        timestamp: new Date(),
        avatar: botAvatar,
      };
      setMessages((msgs) => [...msgs, botMessage]);
    } catch (error) {
      console.error('Error fetching bot response:', error);
    }

    setInputValue('');
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-1 p-6 overflow-auto">
        {messages.map((msg, index) => (
          <Message 
            key={index} 
            sender={msg.sender} 
            avatar={msg.sender === 'user' ? userInitial : botAvatar}
            name={msg.sender === 'user' ? userName : 'Call Assistant'} 
            content={msg.content} 
            isLast={index === messages.length - 1} 
          />
        ))}
      </div>
      <div className="p-4 border-t flex items-center">
        <input
          type="text"
          className="w-full border rounded p-2"
          placeholder="Message..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSend();
              e.preventDefault();
            }
          }}
        />
        <button 
          className="ml-2 bg-blue-500 text-white rounded p-2"
          onClick={handleSend}
        >
          Send
        </button>
      </div>
    </div>
  );
}

export default Chat;
